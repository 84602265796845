import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useLocation } from 'react-router-dom'

import Projeto from './projeto'

// import "./projetos.css"

const Projetos = () => {

    const [loading, setLoading] = useState(true)
    const [projetos, setProjetos] = useState([])

    const { search } = useLocation()

    useEffect(() => {
        axios.get('http://cwbim.com.br/PROJETOS/projetos.json').then(({data}) => {
            setProjetos(data.projetos)
            setLoading(false)
        })
    }, [])

    if (loading) {
        return 'Carregando...'
    }

    if (search) {
        return <Projeto projetos={projetos} />
    }

    return <ul className="lista-projetos">
        {projetos.map(projeto => (
            <li key={projeto.url}>
                <Link to={{search: `?projeto=${projeto.url}`}}>
                    <span className="titulo">{projeto.titulo}</span>
                    <small>
                        {projeto.metragem}m²<br/>
                        {projeto.ano}    
                    </small>
                </Link>
            </li>
        ))}
    </ul>
}

export default Projetos