import React, { useRef } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Fade } from 'react-slideshow-image';

// import "./projeto.css"

const properties = {
    duration: 2000,
    transitionDuration: 300,
    infinite: true,
    indicators: false,
    arrows: false,
    autoplay: false,
    pauseOnHover: true,
}

const Projeto = ({ projetos }) => {
    const { search } = useLocation()
    const { replace } = useHistory()

    const selectionado = projetos.findIndex(projeto => projeto.url === search.replace('?projeto=', ''))
    const projeto = projetos[selectionado]
    const proximo = projetos[selectionado+1] || projetos[0]
    const anterior = projetos[selectionado-1] || projetos[projetos.length-1]

    const slideRef = useRef()

    const nextSlide = () => slideRef.current.goNext()
    const prevSlide = () => slideRef.current.goBack()

    
    if (selectionado === -1) {
        replace({search: ''})
        return null
    }

    return <div className="pagina-projeto">
        <Link to={{search: ''}}>{'<<'} projetos</Link>
        <div className="slide">
            <div className="slide-nav">
                <button onClick={prevSlide} type="button" className="slide-nav">{'<'}</button>
                <button onClick={nextSlide} type="button" className="slide-nav">{'>'}</button>
            </div>
            <Fade ref={slideRef} {...properties}>
                {projeto.fotos.map(foto => <img key={foto} src={foto} />)}
            </Fade >
        </div>
        <div className="titulo-projeto">
            {projeto.titulo}<br/>
            {projeto.metragem}m²<br/>
            {projeto.ano}
        </div>
        <div className="descricao-projeto">
            {projeto.descricao}
        </div>
        <div className="footer-projeto">
            <div className="anterior">
                {anterior && <Link to={{search: `?projeto=${anterior.url}`}}>{anterior.titulo}</Link>}
            </div>
            <div className="seguinte">
                {proximo && <Link to={{search: `?projeto=${proximo.url}`}}>{proximo.titulo}</Link>}
            </div>            
        </div>
    </div>
}

export default Projeto