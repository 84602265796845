import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'

import Projetos from './pages/projetos'

ReactDOM.render(
  <Router>
    <Projetos/>
  </Router>,
  document.getElementById('lista-projetos')
);
